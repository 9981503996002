.stock-chart {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 15px;
  margin-top: 20px;
  width: 100%;
  box-sizing: border-box;
}

.chart-container {
  width: 100%;
  height: 300px;
  margin-top: 20px;
}

@media (max-width: 768px) {
  .stock-chart {
    padding: 10px;
  }

  .chart-container {
    height: 250px;
  }
}

.tab-container {
  display: flex;
  margin-bottom: 20px;
}

.tab-button {
  padding: 10px 20px;
  background-color: #f0f0f0;
  border: none;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
  flex: 1;
}

.tab-button.active {
  background-color: #2196F3;
  color: white;
}

.input-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}

.input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  width: 100%;
  box-sizing: border-box;
}

.button {
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
  width: 100%;
}

.button:hover {
  background-color: #45a049;
}

.button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.error {
  color: #d32f2f;
  margin-bottom: 10px;
  font-size: 14px;
}

.loading {
  color: #1976d2;
  margin-bottom: 10px;
  font-size: 14px;
}

.analysis-result {
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 20px;
  color: white;
}

.analysis-result h3 {
  margin-top: 0;
  font-size: 18px;
}

.analysis-result p {
  margin: 5px 0;
  font-size: 14px;
}

.chart-container {
  margin-top: 20px;
  width: 100%;
}

.about-tab {
  font-size: 16px;
  line-height: 1.6;
}

.about-tab h2 {
  font-size: 24px;
  margin-bottom: 15px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.chart-container,
.about-tab {
  animation: fadeIn 0.5s ease-in;
}

@media (min-width: 768px) {
  .input-container {
    flex-direction: row;
    align-items: flex-start;
  }

  .input {
    flex: 1;
  }

  .button {
    width: auto;
    align-self: stretch;
  }

  .chart-container {
    height: 500px;
  }
}

@media (min-width: 1024px) {
  .stock-chart {
    padding: 30px;
  }

  .about-tab {
    font-size: 18px;
  }

  .about-tab h2 {
    font-size: 28px;
  }
}